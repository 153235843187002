






import ContactQRActionTs from './ContactQRActionTs';

export default class ContactQRAction extends ContactQRActionTs {}
