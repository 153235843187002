import { render, staticRenderFns } from "./UploadQRCode.vue?vue&type=template&id=16b982b2&scoped=true&"
import script from "./UploadQRCode.vue?vue&type=script&lang=ts&"
export * from "./UploadQRCode.vue?vue&type=script&lang=ts&"
import style0 from "./UploadQRCode.vue?vue&type=style&index=0&id=16b982b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b982b2",
  null
  
)

export default component.exports