



















import TransactionQRActionTs from './TransactionQRActionTs';

export default class TransactionQRAction extends TransactionQRActionTs {}
