



























import { ModalImportQRTs } from './ModalImportQRTs';

export default class ModalImportQR extends ModalImportQRTs {}
