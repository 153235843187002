

















import QRCodeActionsTs from './QRCodeActionsTs';

export default class QRCodeActions extends QRCodeActionsTs {}
