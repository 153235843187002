












import ImportQRButtonTs from './ImportQRButtonTs';

export default class ImportQRButton extends ImportQRButtonTs {}
