











import MnemonicQRActionTs from './MnemonicQRActionTs';

export default class MnemonicQRAction extends MnemonicQRActionTs {}
