










































import QRCodePasswordTs from './QRCodePasswordTs';

export default class QRCodePassword extends QRCodePasswordTs {}
