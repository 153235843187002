




























import SignedTransactionQRActionTs from './SignedTransactionQRActionTs';

export default class SignedTransactionQRAction extends SignedTransactionQRActionTs {}
