















import CosignatureQRActionTs from './CosignatureQRActionTs';

export default class CosignatureQRAction extends CosignatureQRActionTs {}
