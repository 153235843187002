
































































import UploadQRCodeTs from './UploadQRCodeTs';

export default class UploadQRCode extends UploadQRCodeTs {}
