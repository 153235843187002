




























import CosignatureSignedTransactionQRActionTs from './CosignatureSignedTransactionQRActionTs';

export default class CosignatureSignedTransactionQRAction extends CosignatureSignedTransactionQRActionTs {}
