


































import TemplateQRActionTs from './TemplateQRActionTs';

export default class TemplateQRAction extends TemplateQRActionTs {}
